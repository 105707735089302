import React from 'react'
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import * as styles from './SolutionLogo.module.scss'

const SolutionLogo = (props) => {
    const { data } = props

    // console.log("LOGO DATA",data)

    const { logoImage } = data

    const image = getImage(logoImage.asset)

    return (
        <div className={styles.solutionLogoSection}>
            <GatsbyImage image={image} alt={"solution-logo"} />
        </div>
    )
}

export default SolutionLogo
