import {useEffect} from "react";
import { useLottie } from "lottie-react";
import clinicalIntel1 from '../../assets/lottie/Clinical Intel/Clinical intel_1.json'
import clinicalIntel2 from '../../assets/lottie/Clinical Intel/Clinical Intel 2.json'
import clinicalIntelType from '../../assets/lottie/Clinical Intel/Clinical Intel Type.json'
import valueBasedCareAdoption from '../../assets/lottie//Value Based Care/Value Based Care - Adoption Curve 2.json'
import valueBasedCareAnalyse from '../../assets/lottie//Value Based Care/Value Based Care - Analyse - no loop.json'
import valueBasedCarePartner from '../../assets/lottie//Value Based Care/Value Based Care - Partner Directly-2.json'
import valueBasedCarePeer from '../../assets/lottie//Value Based Care/Value Based Care - peer to peer_2.json'
import valueBasedCareType from '../../assets/lottie//Value Based Care/Value Based Care - Type.json'
import marginImprovementAutomated from '../../assets/lottie/Margin Improvement/Margin Improvement - automated.json'
import marginImprovementConsultants from '../../assets/lottie/Margin Improvement/Margin Improvement - Consultants.json'
import marginImprovementOptimized from '../../assets/lottie/Margin Improvement/Margin Improvement - Optimized.json'
import marginImprovementPhysician from '../../assets/lottie/Margin Improvement/Margin Improvement - Physician Enterprise 2.json'
import marginImprovementType from '../../assets/lottie/Margin Improvement/Margin Improvement Tech Type.json'

import * as styles from './LottieAnimation.module.scss'

/* 
  prop:
  name = determines which lottie json file to load
  ToDo - dynamically load json files if needed
*/

// css style unique to each lottie file - crop
const svgStyles = {
  // "clinicalIntel1": {
  //   marginBottom: "-20px"
  // }
}

const LottieAnimation = (props) => {

  // animation pause and play determined by isVisible 
  const { name, isVisible = true } = props

  // lookup imported json file by string
  const LottieFiles = {
    "clinicalIntel1": clinicalIntel1,
    "clinicalIntel2": clinicalIntel2,
    "clinicalIntelType": clinicalIntelType,
    "marginImprovementAutomated": marginImprovementAutomated,
    "marginImprovementConsultants": marginImprovementConsultants,
    "marginImprovementOptimized": marginImprovementOptimized,
    "marginImprovementPhysician": marginImprovementPhysician,
    "marginImprovementType": marginImprovementType,
    "valueBasedCareAdoption": valueBasedCareAdoption,
    "valueBasedCareAnalyse": valueBasedCareAnalyse,
    "valueBasedCarePartner": valueBasedCarePartner,
    "valueBasedCarePeer": valueBasedCarePeer,
    "valueBasedCareType": valueBasedCareType
  }

  const lottieClassName = styles[name]

  // load json file based on name prop
  const options = {
    animationData: LottieFiles[name],
    loop: true,
    autoplay: true,
    className: lottieClassName
  }

  const { View, play, pause } = useLottie(options, svgStyles[name] || null);

  useEffect(() => {
    
    // play or pause animation
    isVisible ? play() : pause()

    // pause on unmount
    return () => {
      pause()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible]);

  return View

}

export default LottieAnimation