import React, { useState } from 'react'
// custom Link component
import Link from '../../Link/Link'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import SolutionTab from './SolutionTab'
// import SolutionTabList from './SolutionTabList'
import LottieAnimation from '../../LottieAnimation/LottieAnimation'
import { useMediaQuery } from '@react-hook/media-query'
import ScrollObserver from '../../ScrollObserver/ScrollObserver'
import * as styles from './SolutionTabData.module.scss'

// content shown for each tab
const TabContent = (props) => {
  const { selectedTab, link, linkCLassName } = props

  const selectedTabBg = getImage(selectedTab.backgroundImage.asset)

  return (
    <section className={styles.solutionTabDataSectionSelectedTab}>
      <div className={styles.solutionTabDataSectionSelectedTabBg}>
        <GatsbyImage
          image={selectedTabBg}
          alt={selectedTab.title}
          objectFit="cover"
          objectPosition="top"
          className={styles.solutionTabDataSectionSelectedTabBgImage}
        />
      </div>
      <div className={styles.solutionTabDataSectionSelectedTabContent}>
        <h3 className={styles.solutionTabDataSectionSelectedTabTitle}>
          {selectedTab.title}
        </h3>
        <div
          className={styles.solutionTabDataSectionSelectedTabBodyText}
          dangerouslySetInnerHTML={{ __html: selectedTab.bodyText }}
        ></div>
        <div className={styles.solutionTabDataSectionSelectedTabBottom}>
          <Link
            className={linkCLassName}
            to={link}
          >
            MORE
          </Link>
        </div>
      </div>
    </section>
  )
}

const SolutionTabData = (props) => {
  const [currentTab, setCurrentTab] = useState(0)

  // determine when we are on a mobile screen
  const isMobile = useMediaQuery('all and (max-width: 767px)')

  const { data, slug } = props
  const { title, tabs } = data

  // state set by ScrollOberver
  const [isVisible, setIsVisible] = useState(true);

  const selectedTab = tabs[currentTab]

  // console.log("TABBED DATA", data)

  // const image = getImage(logo.asset)

  const handleTabClick = (index) => {
    setCurrentTab(index)
  }

  const tabList = tabs.map((tab, index) => {
    const tabClass =
      index === currentTab
        ? styles.solutionTabDataSectionTabActive
        : styles.solutionTabDataSectionTab

    return (
      <SolutionTab
        tab={tab}
        tabClass={tabClass}
        key={index}
        tabIndex={0}
        handleTabClick={() => handleTabClick(index)}
      />
    )
  })

  // ToDO - selectedTab.list always appears to be zero so moving <Link> to TabContent component

  // const selectedTabContent =
  //   selectedTab.list.length > 0 ? (
  //     <SolutionTabList list={selectedTab.list} />
  //   ) : (
  //     <Link
  //       target={selectedTab.link.includes('pinc-ai') ? '_self' : '_blank'}
  //       rel="noopener noreferrer"
  //       className={styles.solutionTabDataSectionSelectedTabMoreButton}
  //       to={selectedTab.link}
  //     >
  //       MORE
  //     </Link>
  //   )

  return (
    <div className={styles.solutionTabDataSection}>
      <h2 className={styles.solutionTabDataSectionHeader}>{title}</h2>
      <ScrollObserver setIsVisible={setIsVisible}>
      <div className={styles.solutionTabDataSectionLogo}>
        {/* <GatsbyImage image={image} alt={title}/> */}
        {slug === 'clinical-intelligence' && (
          <LottieAnimation name="clinicalIntelType" isVisible={isVisible} />
        )}
        {slug === 'value-based-care' && (
          <LottieAnimation name="valueBasedCareType" isVisible={isVisible} />
        )}
        {slug === 'margin-improvement' && (
          <LottieAnimation name="marginImprovementType" isVisible={isVisible} />
        )}
      </div>
      </ScrollObserver>
      <div className={styles.solutionTabDataSectionTabs}>{tabList}</div>
      {/* layout changes for mobile vs tablet/desktop */}
      {isMobile ? (
        // show all tabs
        tabs.map((selectedTab, index) => (
          // <TabContent selectedTab={selectedTab} selectedTabContent={selectedTabContent} />
          <TabContent
          key={index}
            selectedTab={selectedTab}
            link={tabs[index]?.link}
            linkCLassName={styles.solutionTabDataSectionSelectedTabMoreButton}
          />
        ))
      ) : (
        // show only selected tab
        // : <TabContent selectedTab={selectedTab} selectedTabContent={selectedTabContent} />
        <TabContent
          selectedTab={selectedTab}
          link={selectedTab.link}
          linkCLassName={styles.solutionTabDataSectionSelectedTabMoreButton}
        />
      )}
    </div>
  )
}

export default SolutionTabData
