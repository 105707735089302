import React, { useEffect, useState, useRef } from "react";
import * as styles from './Callout.module.scss'

/* 
  prop:
  type - loads content and style of callout based on content object
*/

// data for each callout - key is the type prop
const content = {
  clinical1: {
    title: "BETTER, SMARTER CARE FOR PATIENTS",
    text: "The analysis-ready data from PINC AI helps providers measure and improve care delivery and helps life sciences organizations track the impact of their therapies in the real world.",
    color: "teal",
    arrow: "right"
  },
  clinical2: {
    title: "360º View of Performance ",
    text: "With access to +45 percent of U.S. discharges annually, PINC AI can provide the insight clinical leadership needs to help improve patient care, clinical outcomes, and drive revenue.",
    color: "teal",
    arrow: "left"
  },
  clinical3: {
    title: "Real-world Evidence Equals<br>Real-world Results",
    text: "The HIPAA-compliant healthcare database in PINC AI delivers robust, real-world data to drive informed decisions. From late-stage drug development to post-marketing studies, our unparalleled data can improve quality, lower costs, reduce risks and help ensure patient safety. ",
    color: "teal",
    arrow: "right"
  },
  clinical4: {
    title: "Clinical decision support for<br>measurable improvement",
    text: "Effective clinical decision support requires ongoing management: tweaking the logic for accurate results and monitoring how providers respond to it. PINC AI does this at scale for use cases like waste reduction, documentation and coding, and prior authorization. ",
    color: "teal",
    arrow: "left"
  },
  value1: {
    title: "Peer-to-Peer Collaboration",
    text: "Members of the PINC AI Population Health Collaborative consistently outperform their peers in achieving Medicare shared savings. As a team, we support, grow and innovate together.   ",
    color: "red",
    arrow: "right"
  },
  value2: {
    title: "CONDENSE YOUR ADOPTION CURVE",
    text: "Improve care delivery, enhance patient experience and optimize hospital-clinician alignment with a rapid transformation to value-based care. Data + consulting + peer-to-peer collaboration ensures success. ",
    color: "red",
    arrow: "left"
  },
  value3: {
    title: "analyze and improve cross-continuum",
    text: "Accountable care organizations (ACOs) can now visualize their populations’ cost and medical utilization by key service categories while surfacing opportunities and maximizing ROI with PINC AI. ",
    color: "red",
    arrow: "right"
  },
  value4: {
    title: "Partner directly with employers",
    text: "Provide the highest quality care with Contigo Health®, our fast-growing, direct-to-employer contracting platform. Connecting self-insured employers with our nationwide provider network, you can deliver the best care options available for your associates.",
    color: "red",
    arrow: "left"
  },
  margin1: {
    title: "Margin-Minded Consultants",
    text: "You need results from your healthcare improvement partners and PINC AI delivers. Our unmatched data, technology and unique expertise in healthcare means our experts arrive with margin improvement solutions on day one.",
    color: "purple",
    arrow: "right"
  },
  margin2: {
    title: "AUTOMATE FOR EFFICIENCY",
    text: "When you automate processes, productivity increases. PINC AI can manage everything from procure-to-pay, including contracting, ordering, invoicing and payment, all on a single, intuitive cloud-based platform.",
    color: "purple",
    arrow: "left"
  },
  margin3: {
    title: "Optimize Your Workforce",
    text: "Optimize your workforce with PINC AI data and you could see a 14:1 return on investment without compromising quality of care. Many of our partners are able to identify improvements and reduce labor costs almost instantly.",
    color: "purple",
    arrow: "right"
  },
  margin4: {
    title: "Manage Physician Enterprise",
    text: "Provide your physicians, medical group leaders and hospital executives with transformational strategies to address their complex and evolving needs. Our Physician Enterprise team delivers cost-effective insights backed by data.",
    color: "purple",
    arrow: "left"
  }
}



const Callout = (props) => {

  const { type } = props

  const ref = useRef()
  const [isInView, setIsInView] = useState(false);
  const title = content[type].title
  const text = content[type].text
  const color = content[type].color
  const arrowDirection = content[type].arrow

  // these class names are unique to each banner
  const containerClass = `${styles.container} ${styles[type]}`
  const calloutClass = `${styles.callout} ${styles[arrowDirection]} ${isInView ? styles.show : ''}`
  const bannerClass = `${styles.banner} ${styles[color]} ${styles[arrowDirection]}`


// use IntersectionObserver to detect when element is scrolled into viewport

  useEffect(() => {

    const refCopy = ref.current

    const onScroll = (entries) => {

      if (entries[0].isIntersecting) {
        setIsInView(true)
      } 
      // if (!entries[0].isIntersecting) {
      //   setIsInView(false)
      // }
    }

    const observer = new IntersectionObserver(onScroll, {
      root: null,
      // increases size of root element to include offscreen elements
      // rootMargin: "0% 400% 0% 400%",
      // call when within threshold - range is 0 to 1
      threshold: 0.5
    });

      observer.observe(ref.current)

    return () => observer.unobserve(refCopy)
  }, []);

  return (
    <div className={containerClass} ref={ref}>
      <div className={calloutClass}>
        <div className={bannerClass} >
          <h5
            className={styles.title}
            dangerouslySetInnerHTML={{ __html: title }}
          >
          </h5>
        </div>
        <p className={styles.text}>{text}</p>
      </div>
    </div>
  )

}

export default Callout

