// extracted by mini-css-extract-plugin
export var solutionTabDataSection = "SolutionTabData-module--solutionTabDataSection--793e0";
export var solutionTabDataSectionHeader = "SolutionTabData-module--solutionTabDataSectionHeader--f2465";
export var solutionTabDataSectionLogo = "SolutionTabData-module--solutionTabDataSectionLogo--d4373";
export var solutionTabDataSectionSelectedTab = "SolutionTabData-module--solutionTabDataSectionSelectedTab--afed4";
export var solutionTabDataSectionSelectedTabBg = "SolutionTabData-module--solutionTabDataSectionSelectedTabBg--d709e";
export var solutionTabDataSectionSelectedTabBgImage = "SolutionTabData-module--solutionTabDataSectionSelectedTabBgImage--fb276";
export var solutionTabDataSectionSelectedTabBodyText = "SolutionTabData-module--solutionTabDataSectionSelectedTabBodyText--0744b";
export var solutionTabDataSectionSelectedTabBottom = "SolutionTabData-module--solutionTabDataSectionSelectedTabBottom--3af41";
export var solutionTabDataSectionSelectedTabContent = "SolutionTabData-module--solutionTabDataSectionSelectedTabContent--833ec";
export var solutionTabDataSectionSelectedTabMoreButton = "SolutionTabData-module--solutionTabDataSectionSelectedTabMoreButton--4f331";
export var solutionTabDataSectionSelectedTabTitle = "SolutionTabData-module--solutionTabDataSectionSelectedTabTitle--366a5";
export var solutionTabDataSectionTab = "SolutionTabData-module--solutionTabDataSectionTab--e48bb";
export var solutionTabDataSectionTabActive = "SolutionTabData-module--solutionTabDataSectionTabActive--270f7";
export var solutionTabDataSectionTabContent = "SolutionTabData-module--solutionTabDataSectionTabContent--cf1b0";
export var solutionTabDataSectionTabHeader = "SolutionTabData-module--solutionTabDataSectionTabHeader--8017a";
export var solutionTabDataSectionTabTitle = "SolutionTabData-module--solutionTabDataSectionTabTitle--12566";
export var solutionTabDataSectionTabs = "SolutionTabData-module--solutionTabDataSectionTabs--721f9";
export var solutionTabList = "SolutionTabData-module--solutionTabList--8a67a";
export var solutionTabListItem = "SolutionTabData-module--solutionTabListItem--ab966";
export var solutionTabListItemBodyText = "SolutionTabData-module--solutionTabListItemBodyText--36b5d";
export var solutionTabListItemIcon = "SolutionTabData-module--solutionTabListItemIcon--32199";
export var solutionTabListItemLink = "SolutionTabData-module--solutionTabListItemLink--f582c";
export var solutionTabListItemTextWrap = "SolutionTabData-module--solutionTabListItemTextWrap--40ede";
export var solutionTabListItemTitle = "SolutionTabData-module--solutionTabListItemTitle--279eb";