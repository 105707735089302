import React from 'react'
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import * as styles from './SolutionDescription.module.scss'

const SolutionDescription = (props) => {
    const { data } = props

    // console.log("DESCRIPTION DATA",data)

    const { title, bodyText, backgroundImage } = data

    const image = getImage(backgroundImage.asset)

    return (
        <div className={styles.solutionDescriptionSection} >
            <div className={styles.solutionDescriptionBg}>
                <GatsbyImage image={image} alt={title} className={styles.solutionDescriptionBgImg} objectPosition="top" />
            </div>
            <div className={styles.solutionDescriptionContent}>
                <h2 className={styles.solutionDescriptionTitle}>
                    {title}
                </h2>
                <div className={styles.solutionDescriptionBodyText}>
                    {bodyText}
                </div>
            </div>
        </div>
    )
}

export default SolutionDescription
