import React, { useState } from 'react'
// import { GatsbyImage, getImage } from "gatsby-plugin-image"
import MobileClinicalDiagram from '../../../assets/images/MobileDiagram-CI-MobileImages.jpg'
import MobileClinicalDiagram2x from '../../../assets/images/MobileDiagram-CI-MobileImages@2x.jpg'
import MobileMarginDiagram from '../../../assets/images/MobileDiagram-MI-MobileImages.jpg'
import MobileMarginDiagram2x from '../../../assets/images/MobileDiagram-MI-MobileImages@2x.jpg'
import MobileValueBasedDiagram from '../../../assets/images/MobileDiagram-VBC-Mobile.jpg'
import MobileValueBasedDiagram2x from '../../../assets/images/MobileDiagram-VBC-Mobile@2x.jpg'
import LottieAnimation from '../../LottieAnimation/LottieAnimation'
import Callout from '../../Callout/Callout'
import ScrollObserver from '../../ScrollObserver/ScrollObserver'
import * as styles from './SolutionDiagram.module.scss'

const SolutionDiagram = (props) => {
    const { slug } = props

    // state set by ScrollOberver
    const [isVisible, setIsVisible] = useState(true);

    // console.log("DIAGRAM DATA", data)

    // const { diagramImage } = data

    // const image = getImage(diagramImage.asset)

    const mobileDiagram = slug === 'clinical-intelligence' ? MobileClinicalDiagram : slug === 'value-based-care' ? MobileValueBasedDiagram : MobileMarginDiagram
    const mobileDiagram2x = slug === 'clinical-intelligence' ? MobileClinicalDiagram2x : slug === 'value-based-care' ? MobileValueBasedDiagram2x : MobileMarginDiagram2x

    return (
        <div className={styles.solutionDiagramSection}>
            <div className={styles.solutionDiagramHeader}>
                <h2>HOW PINC AI CREATES VALUE</h2>
            </div>
            {/* <GatsbyImage image={image} className={styles.solutionDiagramImage} alt="diagram"/> */}
            <ScrollObserver setIsVisible={setIsVisible} >
            <div className={styles.diagrams}>
                {slug === 'clinical-intelligence' &&
                    <>
                        <Callout type="clinical1" />
                        <Callout type="clinical2" />
                        <Callout type="clinical3" />
                        <Callout type="clinical4" />
                        <div className={styles.lottie}>
                            <LottieAnimation name="clinicalIntel1" isVisible={isVisible} />
                            <LottieAnimation name="clinicalIntel2" isVisible={isVisible} />
                        </div>
                    </>
                }
                {slug === 'value-based-care' &&
                    <>
                        <Callout type="value1" />
                        <Callout type="value2" />
                        <Callout type="value3" />
                        <Callout type="value4" />
                        <div className={styles.lottie}>
                            <LottieAnimation name="valueBasedCarePeer" isVisible={isVisible} />
                            <LottieAnimation name="valueBasedCareAdoption" isVisible={isVisible} />
                            <LottieAnimation name="valueBasedCareAnalyse" isVisible={isVisible} />
                            <LottieAnimation name="valueBasedCarePartner" isVisible={isVisible} />
                        </div>
                    </>
                }
                {slug === 'margin-improvement' &&
                    <>
                        <Callout type="margin1" />
                        <Callout type="margin2" />
                        <Callout type="margin3" />
                        <Callout type="margin4" />
                        <div className={styles.lottie}>
                            <LottieAnimation name="marginImprovementConsultants" isVisible={isVisible} />
                            <LottieAnimation name="marginImprovementAutomated" isVisible={isVisible} />
                            <LottieAnimation name="marginImprovementOptimized" isVisible={isVisible} />
                            <LottieAnimation name="marginImprovementPhysician" isVisible={isVisible} />
                        </div>
                    </>
                }
                <img
                    src={mobileDiagram2x}
                    srcSet={`${mobileDiagram}, ${mobileDiagram2x} 2x`}
                    className={styles.solutionDiagramMobileImage}
                    alt="Mobile Solution Diagram" />
            </div>
            </ScrollObserver>
            {/* <LottieAnimation name="clinicalIntelType" /> */}
        </div>
    )
}

export default SolutionDiagram
