import React from 'react'
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import iconarrow from '../../../assets/images/blog-item-arrow.svg'
// custom Link component
import Link from '../../Link/Link'
import * as styles from './SolutionDouble.module.scss'

const SolutionDoubleSide = (props) => {
    const { data, side } = props

    // console.log(side)

    const { topText, title, bodyText, link, backgroundImage } = data

    const sideClass = side === 'left' ? styles.solutionDoubleLeftSide : styles.solutionDoubleRightSide

    const image = getImage(backgroundImage.asset)

    return (
        <section className={sideClass}>
            <GatsbyImage className={styles.solutionDoubleSideBg}
                image={image} alt={title} objectFit="cover" objectPosition="top" />

            <div className={styles.contentWrapper}>
                <div className={styles.solutionDoubleSideTopText}>
                    {topText}
                </div>
                <h2 className={styles.solutionDoubleSideTitle}>
                    {title}
                </h2>
                <div
                    className={styles.solutionDoubleSideBodyText}
                    dangerouslySetInnerHTML={{ __html: bodyText }}
                >
                </div>
                <Link to={link} className={styles.solutionDoubleSideButton}>
                    LEARN MORE <img src={iconarrow} alt="iconarrow" />
                </Link>
            </div>
        </section>
    )
}

export default SolutionDoubleSide
