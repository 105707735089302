import React from 'react'
import * as styles from './SolutionTabData.module.scss'

const SolutionTab = (props) => {
    const { tab, handleTabClick, tabClass } = props

    return (
        <div className={tabClass} role="button" 
            onKeyDown={handleTabClick} onClick={handleTabClick} tabIndex={0}>
            <div className={styles.solutionTabDataSectionTabTitle}>
                {tab.title}
            </div>
        </div>
    )
}

export default SolutionTab