import React from 'react'
import {graphql} from 'gatsby'
import Layout from '../components/Layout/Layout'
import SolutionDescription from '../components/SolutionsPage/Description/SolutionDescription'
import SolutionDiagram from '../components/SolutionsPage/Diagram/SolutionDiagram'
import SolutionDouble from '../components/SolutionsPage/Double/SolutionDouble'
import SolutionLogo from '../components/SolutionsPage/Logo/SolutionLogo'
import SolutionTabData from '../components/SolutionsPage/TabData/SolutionTabData'
import {createGlobalStyle} from 'styled-components'
// import VantaBG from '../components/vanta/VantaBG'
import * as styles from '../styles/Solutions.module.scss'
import Seo from '../components/Seo/Seo'
import HeroHeader from '../components/HundredTop/HeroHeader/HeroHeader'


export const query = graphql`
  query solution($id: String!) {
    solution: sanitySolutionpage(id: {eq: $id}) {
      id
      browserTitle
      seoData {
        title
        description
        image {
          asset {
            url
          }
        }
      }
      title: solutionTitle
      slug {
        current
      }
      header: header {
        _rawTitle
        _rawSubheader
        companyName
        slogan
        buttonOptions {
          buttonText
          buttonFile {
            asset {
              url
              title
            }
          }
          buttonData
          buttonContext
        }
        topLogo {
          asset {
            url
            title
          }
        }
        backgroundImage {
          asset {
            url
            title
          }
        }
      }
      hero: heroSection {
        title
        bodyText
        backgroundImage {
            asset {
                gatsbyImageData(layout: FULL_WIDTH)
            }
        }
        icon {
            asset {
                url
            }
        }
      }
      description: descriptionSection {
        title
        bodyText
        backgroundImage {
          asset {
            gatsbyImageData
          }
        }
      }
      diagram: diagramSection {
        diagramImage {
          asset {
            gatsbyImageData
          }
        }
      }
      logo: logoSection {
        logoImage {
          asset {
            gatsbyImageData
          }
        }
      }
      tabs: tabbedDataSection {
        title
        logo {
          asset {
            gatsbyImageData
          }
        }
        tabs {
          title
          bodyText
          backgroundImage {
            asset {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
          link
          list {
            title
            bodyText
            link
          }
        }
      }
      double: doubleSection {
        leftSection {
          topText
          title
          bodyText
          link
          backgroundImage {
            asset {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
        rightSection {
          topText
          title
          bodyText
          link
          backgroundImage {
            asset {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
      settings {
        primaryColor
        secondaryColor
      }
    }
  }`


  const GlobalStyles = createGlobalStyle`
  html {
      --color-primary: ${props => props.settings.primaryColor};
      --color-secondary: ${props => props.settings.secondaryColor};
  }
`

const SolutionsTemplate = props => {
    const {data} = props

    // console.log("SOLUTION DATA", data)

    const {header, description, diagram, logo, tabs, double, settings, slug} = data.solution
    const color = settings.primaryColor;
    return (
        <Layout>
            <GlobalStyles settings={settings} />
            <div className={styles.solutionsPageWrap}>
                <HeroHeader data={header} themeContext={slug.current}/>
                {/* <VantaBG data={hero} color={color}/> */}


                <SolutionDescription color={color} data={description}/>

                <SolutionDiagram data={diagram} slug={slug.current}/>

                <SolutionLogo data={logo}/>

                <SolutionTabData data={tabs} slug={slug.current}/>

                <SolutionDouble data={double}/>
            </div>
        </Layout>
    )
}

export default SolutionsTemplate

export const Head = (props) => {
  const { data } = props;
  return (
    <Seo title={data.solution.browserTitle} seo={data.solution.seoData} />
  )
}