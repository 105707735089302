// extracted by mini-css-extract-plugin
export var banner = "Callout-module--banner--a17a4";
export var callout = "Callout-module--callout--ab2be";
export var clinical1 = "Callout-module--clinical1--22eef";
export var clinical2 = "Callout-module--clinical2--1cf2f";
export var clinical3 = "Callout-module--clinical3--06b2e";
export var clinical4 = "Callout-module--clinical4--e7747";
export var container = "Callout-module--container--71164";
export var margin1 = "Callout-module--margin1--8423d";
export var margin2 = "Callout-module--margin2--87eca";
export var margin3 = "Callout-module--margin3--6e8cb";
export var margin4 = "Callout-module--margin4--e4c62";
export var purple = "Callout-module--purple--bb37c";
export var red = "Callout-module--red--0b892";
export var right = "Callout-module--right--6a8ec";
export var show = "Callout-module--show--f7b90";
export var teal = "Callout-module--teal--ca274";
export var text = "Callout-module--text--a1ff6";
export var title = "Callout-module--title--b735a";
export var value1 = "Callout-module--value1--cf5a4";
export var value2 = "Callout-module--value2--f8b4e";
export var value3 = "Callout-module--value3--b75e1";
export var value4 = "Callout-module--value4--be077";