import React from 'react'
import SolutionDoubleSide from './SolutionDoubleSide'
import * as styles from './SolutionDouble.module.scss'

const SolutionDouble = (props) => {
    const { data } = props

    // console.log("DOUBLE DATA", data)

    const { leftSection, rightSection } = data

    return (
        <div className={styles.solutionDoubleSection}>
            <SolutionDoubleSide side='left' data={leftSection} />
            <SolutionDoubleSide side='right' data={rightSection} />
        </div>
    )
}

export default SolutionDouble
