// extracted by mini-css-extract-plugin
export var clinicalIntel1 = "LottieAnimation-module--clinicalIntel1--14c0d";
export var clinicalIntel2 = "LottieAnimation-module--clinicalIntel2--6c3be";
export var clinicalIntelType = "LottieAnimation-module--clinicalIntelType--9569b";
export var marginImprovementAutomated = "LottieAnimation-module--marginImprovementAutomated--eac1c";
export var marginImprovementConsultants = "LottieAnimation-module--marginImprovementConsultants--376b2";
export var marginImprovementOptimized = "LottieAnimation-module--marginImprovementOptimized--3cb29";
export var marginImprovementPhysician = "LottieAnimation-module--marginImprovementPhysician--8236a";
export var marginImprovementType = "LottieAnimation-module--marginImprovementType--64574";
export var valueBasedCareAdoption = "LottieAnimation-module--valueBasedCareAdoption--76c9f";
export var valueBasedCareAnalyse = "LottieAnimation-module--valueBasedCareAnalyse--d1846";
export var valueBasedCarePartner = "LottieAnimation-module--valueBasedCarePartner--ac172";
export var valueBasedCarePeer = "LottieAnimation-module--valueBasedCarePeer--1a69a";
export var valueBasedCareType = "LottieAnimation-module--valueBasedCareType--05feb";